<template>
    <div class="card-basic contact-block" v-if="kontaktperson!='' || telefonnummer!='' || eMail!=''">
        <h5 class="post-heading">Kiminle iletişime geçebilirim?</h5>
        <ul class="contact">
            <li class="contact-item do-not-translate" v-if="kontaktperson!==null && kontaktperson !== ''" data-wg-notranslate="">
                <span class="material-icons-outlined icon" aria-hidden="true">person</span>
                <span class="text">{{kontaktperson}}</span>
            </li>
            <li class="contact-item" v-if="telefonnummer!==null && telefonnummer!==''">
                <span class="material-icons-outlined icon" aria-hidden="true" data-wg-notranslate="">phone</span>
                <span class="text">{{telefonnummer}}</span>
            </li>
            <li class="contact-item" v-if="eMail!==null">
                <span class="material-icons-outlined icon" aria-hidden="true" data-wg-notranslate="">mail</span>
                <a :href="'mailto:'+eMail" :alt="'E-Mail senden an'+eMail" class="text">{{eMail}}</a>
            </li>
        </ul>

        <form action="#!">
            <p class="text-black mb20 mt-4 font14">Ya da formumuzu kullanarak bir mesaj gönderin:</p>
            <div class="form-group">
                <input type="text" class="form-control" v-model="data.sender" placeholder="E-posta adresiniz">
                <div ref="email-help" class="help" v-show="errors.sender">Geçerli bir e-posta biçimi değil.</div>
            </div>
            <div class="form-group">
                <textarea class="form-control" v-model="data.message" cols="30" rows="10" placeholder="Mesajınız"></textarea>
                <div ref="message-help" class="help" v-show="errors.message">Bir mesaj girin</div>
            </div>
            <div class="form-check form-check-inline">
                <div>
                    Mesajı gönderdiğinizde, doğrudan sağlayıcıyla iletişime geçersiniz. Sağlayıcının veri işlemesine ilişkin bilgiler sağlayıcının gizlilik politikasında bulunabilir.
                </div>
            </div>
            <button @click="sendMessage" class="btn btn-hover btn-block mt30">Mesaj gönder</button>
        </form>
    </div>
</template>

<script>
    import { sendMail } from '@/api/email';
    import { validEmail } from '@/utils/validate';
    import { getFieldValues, hasErrors } from '@/utils/helpers';

    export default {
        name: 'ContactBlock',
        props: {
            contact: {
                type: Object,
                required: true,
                default: {}
            },
            contentId: {
                type: Number,
                required: true,
                default: null
            },
        },
        data() {
            return {
                data: {
                    email_id: 17,
                    message: '',
                    content_id: undefined,
                    sender: '',
                    policies: false,
                    link: window.location.href,
                    app: this.$appName
                },
                errors: {
                    datenschutz: false,
                    sender: false,
                    message: false
                }
            }
        },
        created() {
            this.data.content_id = this.contentId;
        },
        computed: {
            kontaktperson(){
                var value = getFieldValues(this.contact,'kontaktperson');
                return value!=null?value:'';
            },
            telefonnummer(){
                var value = getFieldValues(this.contact,'telefonnummer');
                return value!=null?value:'';
            },
            eMail(){
                var value = getFieldValues(this.contact,'e-mail_adresse');
                return value!==null?value:'';
            },
        },
        methods:{
            getFieldValues,
            sendMessage: function (event){
                event.preventDefault();

                if(this.data.sender == "" || !validEmail(this.data.sender)){
                    this.errors.sender = true;
                }
                else{
                    this.errors.sender = false;
                }

                if(this.data.message==""){
                    this.errors.message = true;
                }
                else{
                    this.errors.message = false;
                }

                if(this.data.datenschutz==false){
                    this.errors.datenschutz = true;
                }
                else{
                    this.errors.datenschutz = false;
                }

                if(!hasErrors(this.errors)){
                    sendMail(this.data).then( response => {
                        this.$notify({
                            duration: 3500,
                            title: "E-Mail gesendet",
                            text: "Deine E-Mail wurde versendet!"
                        });

                        this.data.sender = "";
                        this.data.message = "";
                        this.data.datenschutz = false;

                    }).catch(() => {
                        this.$notify({
                            type: 'error',
                            duration: 3500,
                            title: 'Error!',
                            text: "Es ist ein Fehler aufgetreten. Deine E-Mail konnte nicht gesendet werden."
                        });
                    });
                }

            },

        }
    }
</script>
<style lang="scss" scoped="">
    @import '@/scss/_mixins.scss';
    @import '@/scss/_variables.scss';

    .contact-block{
     @media (min-width: 991px) {
        box-shadow: none;
        padding: 0 40px;
    }

    .form-group, .form-check{
        display: block;

        .form-control{
            display: block;
        }

        .help{
            color: red;
            display: block;
            margin-top: 2px;
        }
    }

    .contact{
        @media (max-width: 991px) {
            margin-left: 0px;
        }

        @media (max-width: 1200px) {
            flex-direction: column;
        }
    }
}

</style>